@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:wght@300;400;700&display=swap');

:root {
  --color-bg: #2e2e2e;
  --color-bg-darker: #1d1d1d;
  --color-fg: rgba(255, 255, 255, 0.87);
  --color-muted: rgba(255, 255, 255, 0.54);
  --font-heading: 'Lobster', cursive;
  --font-body: 'Open Sans', sans-serif;
}

html, body {
  background-color: var(--color-bg);
  font-size: 20px;
}

body, .table {
  color: var(--color-fg);
  font-family: var(--font-body);
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
}

h1.display {
  font-size: 4rem;
}

h2.tagline {
  color: var(--color-muted);
}

section {
  padding: 64px 0;
}

@media (min-width: 1200px) {
  section {
    padding: 128px 0;
  }
}

footer {
  background-color: var(--color-bg-darker);
}

footer .copyright {
  font-size: .5rem;
}

a {
  color: var(--color-fg);
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px dotted var(--color-muted);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--color-muted);
}

/* Table */
.table {
  width: auto !important;
  margin: 0;
  display: inline-table;
}

.table td {
  background-color: transparent !important;
  color: var(--color-fg) !important;
}

/* Video */
.video {
  display: inline-block;
  height: 0;
  padding: 56.25% 0 0;
  position: relative;
  width: 100%;
}

.video > * {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}