.item {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  flex-flow: row nowrap;
  width: 100%;
}

.item span {
  font-size: 1rem;
  white-space: nowrap;
}

.item span:first-child {
  width: auto;
}

.item span:nth-child(2) {
  flex: 1 1 0px;
  overflow: hidden;
}

.item span:nth-child(2) span {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.item span:last-child {
  text-align: right;
  width: auto;
}

@media (min-width: 800px) {
  .item span {
    font-size: 1.75rem;
  }
}