/* Splash */
.splash {
  height: 100vh;
  position: relative;
  width: 100%;
}

.carousel {
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.carousel-inner,
.carousel-item,
.carousel img {
  height: 100%;
  object-fit: cover;
}

.splash .content {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  padding: 3rem;
  position: relative;
  width: 100%;
  z-index: 2;
}

.splash .content .logo {
  filter: drop-shadow(0px 5px 20px #000);
  max-width: 350px;
  width: 75%;
}

.splash .content .bookings {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2rem;
}
