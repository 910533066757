#booked {
  background-image: url("../../public/assets/splashes/ThisChristography-20200613-2.jpg");
  background-position: center;
  background-size: cover;
}

.modal-content {
  background-color: #2e2e2e !important;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
}